import React from "react";
import Hero from "../components/Hero";

function Culture() {
  const cultureHeroContent = (
    <h1 className="culture-hero-title">
      Where <b className="hero-bold">People</b> Matter The Most
    </h1>
  );

  return (
    <>
      <Hero content={cultureHeroContent} background="culture-hero" />
      <p className="pageContent">hello, culture page</p>
    </>
  );
}

export default Culture;
